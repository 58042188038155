<template>
  <el-dialog
    title="Baixar conta"
    custom-class="PayBill"
    width="70%"
    append-to-body
    v-model="shouldShow"
  >
    <form>
      <el-row type="flex">
        <el-col> </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Código da conta:</h4>
      </el-row>
      <base-input
        placeholder="A busca será feita automaticamente"
        v-model="billCode"
        ref="inputBillCode"
        mask="#*"
      ></base-input>
    </form>
    <el-empty
      v-if="!billCode"
      description="Digite o código da conta para buscar"
    ></el-empty>
    <el-empty
      v-else-if="!isLoading && !bill"
      description="Conta não encontrada"
    ></el-empty>
    <el-empty
      v-else-if="!isLoading && !payment"
      description="Parcela da conta não encontrada"
    ></el-empty>
    <div v-show="bill && payment">
      <el-descriptions
        class="mt-2"
        title="Detalhes da conta"
        :column="2"
        size="small"
        border
      >
        <el-descriptions-item>
          <template #label>Empresa</template>
          {{ bill?.firm?.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Fornecedor</template>
          {{ bill?.partner?.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Parcela</template>
          {{ payment?.order || 1 }} /
          {{ bill?.quota || 1 }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Data Aquisição</template>
          {{ new Date(bill?.bought_at).toLocaleDateString() }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Valor Total</template>
          {{ currencyFormatter.format(Number(bill?.amount)) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Saldo Restante</template>
          {{
            Number(getRemainingAmount())
              ? currencyFormatter.format(Number(getRemainingAmount()))
              : "-"
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Valor da Parcela</template>
          {{ currencyFormatter.format(Number(payment?.amount)) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Vencimento</template>
          {{ new Date(payment?.expires_at).toLocaleDateString() }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Centro de Custo</template>
          {{ bill?.cost?.reference }}
        </el-descriptions-item>
      </el-descriptions>
      <el-row type="flex" justify="start">
        <div style="padding-left: 4px">Valor pago:</div>
      </el-row>
      <base-input v-model="amount_paid" type="money"></base-input>
      <el-row type="flex" justify="start">
        <div style="padding-left: 4px">Pago em:</div>
      </el-row>
      <el-date-picker
        v-model="paid_at"
        type="date"
        size="medium"
        placeholder="Pago em:"
        :shortcuts="shortcuts"
        format="DD/MM/YYYY"
      >
      </el-date-picker>
      <el-row type="flex" justify="start">
        <div style="padding-left: 4px">Observação:</div>
      </el-row>
      <base-input
        type="textarea"
        v-model="obs"
        label="Observações:"
      ></base-input>
    </div>
    <template #footer>
      <el-row justify="end" type="flex">
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          :disabled="!payment"
          @click="payBill"
          class="button"
          >Baixar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElMessageBox, ElNotification } from "element-plus";
export default {
  props: ["showModal", "refId"],
  emits: ["close-modal", "should-update"],
  components: { BaseInput },
  watch: {
    billCode(v) {
      if (this.delayFetchBill) {
        clearTimeout(this.delayFetchBill);
        this.delayFetchBill = null;
      }
      this.bill = null;
      this.payment = null;
      this.delayFetchBill = setTimeout(() => {
        this.fetchBillPaymentByRef(v);
      }, 400);
    },
    bill(v) {
      if (v) {
        const unpaid = this.payment;
        if (unpaid) {
          this.amount_paid = unpaid.amount;
          this.paid_at = new Date(unpaid.expires_at);
        }
      }
    },
    refId(v) {
      if (v) this.billCode = v;
      else this.billCode = null;
    },
    showModal(v) {
      if (v) {
        this.amount_paid = null;
        this.paid_at = null;
        this.obs = null;
        this.bill = null;
        this.$refs?.inputBillCode?.focus?.();
      }
    },
  },
  data() {
    return {
      billCode: "",
      paid_at: null,
      delayFetchBill: null,
      amount_paid: 0,
      loading: null,
      obs: "",
      bill: null,
      payment: null,
      isLoadingSave: false,
      isLoading: false,
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }),
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    getRemainingAmount() {
      const total = Number(this.bill?.amount);
      if (total) {
        const paid = this.bill?.payments
          ?.filter((p) => p.status === "paid")
          ?.map((p) => Number(p.amount))
          ?.reduce((t, p) => (t += p), 0);

        return total - paid - Number(this.payment?.amount || 0);
      }
      return total || 0;
    },
    hasUnpaidPaymentsBeforeThisOne(payment) {
      const order = payment.order;

      return !!this.bill?.payments
        ?.filter((p) => p.order < order)
        ?.filter((p) => p.status != "paid")?.length;
    },
    async checkIfUserCouldPayThisPayment() {
      if (!this.checkIfPaymentHasValidAmount()) {
        this.$alert(
          "Você não pode dar baixa em uma parcela com valor R$ 0,00",
          "Parcela inválida",
          {
            confirmButtonText: "OK",
          }
        );

        return false;
      }

      const p = this.payment;
      if (p) {
        const hasUnpaidPaymentsBeforeThisOne =
          this.hasUnpaidPaymentsBeforeThisOne(p);

        if (!hasUnpaidPaymentsBeforeThisOne) return true;

        return await ElMessageBox.confirm(
          "Existem pagamentos em aberto anteriores à este",
          "Pagamentos em aberto",
          {
            confirmButtonText: "Pagar mesmo assim",
            cancelButtonText: "Cancelar",
            type: "warning",
          }
        ).then(
          () => true,
          () => false
        );
      } else return false;
    },
    showLoading() {
      this.loading = this.$loading({
        target: ".PayBill",
        text: "Carregando conta",
      });
    },
    closeLoading() {
      this.loading?.close();
    },
    checkIfPaymentHasValidAmount() {
      return Number(this.payment.amount) > 0;
    },
    async alertUserAndCreateNewPayment() {
      const response = await ElMessageBox.confirm(
        "Você pode criar uma nova parcela com o saldo restante",
        "Baixa parcial da parcela",
        {
          confirmButtonText: "Criar parcela com saldo restante",
          cancelButtonText: "Não criar nova parcela",
          type: "warning",
        }
      ).then(
        () => true,
        () => false
      );

      if (response) {
        await this.createPayment({
          ...this.payment,
          amount: Number(this.payment.amount) - Number(this.amount_paid),
          order: this.payment.total + 1,
          paid_at: null,
          amount_paid: null,
        });
      }
    },
    createPayment(payment) {
      fetch(`${this.$store.state.apiUrl}bills/${this.bill.uid}/payments`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(payment),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            if (this.refId) this.$emit("close-modal");
            this.fetchBillPaymentByRef(this.billCode);
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar a nova parcela",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    async payBill() {
      const amount_paid = this.amount_paid;
      const paid_at = this.paid_at;
      const obs = this.obs;
      const paymentId = this.payment?.uid;
      if (!paymentId) return;

      if (!(await this.checkIfUserCouldPayThisPayment())) return;

      if (amount_paid < this.payment?.amount)
        await this.alertUserAndCreateNewPayment();

      this.isLoadingSave = true;
      fetch(
        `${this.$store.state.apiUrl}bills/${this.bill.uid}/payments/${paymentId}`,
        {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ amount_paid, paid_at, obs }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            if (this.refId) this.$emit("close-modal");
            this.fetchBillPaymentByRef(this.billCode);
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar a conta",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    fetchBillPaymentByRef(ref) {
      if (!ref) return;
      this.showLoading();
      const url = new URL(`${this.$store.state.apiUrl}payments`);

      url.search = new URLSearchParams({ ref });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.payment = json?.[0];
          this.bill = this.payment?.bill;
        })
        .catch(() => (this.hasError = true))
        .finally(() => this.closeLoading());
    },
  },
  name: "FilterBillsModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-2 {
  margin-top: 8px;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>