<template>
  <el-dialog
    title="Baixar conta"
    width="70%"
    append-to-body
    v-model="shouldShow"
  >
    <div>
      <el-descriptions
        v-if="bill"
        class="mt-2"
        title="Detalhes da conta"
        :column="2"
        size="small"
        border
      >
        <el-descriptions-item>
          <template #label>Empresa</template>
          {{ bill?.firm?.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Fornecedor</template>
          {{ bill?.partner?.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Parcelas</template>
          {{ bill?.quota || 1 }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Data de Cadastro</template>
          {{ dateFormatter.format(new Date(bill?.created_at)) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Valor Total</template>
          {{ currencyFormatter.format(Number(bill?.amount)) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Saldo Restante</template>
          {{ currencyFormatter.format(Number(getRemainingAmount())) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Vencimento</template>
          {{ dateFormatter.format(new Date(bill.expires_at)) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Centro de Custo</template>
          {{ bill?.cost?.reference }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Observação</template>
          {{ bill?.obs || "" }}
        </el-descriptions-item>
      </el-descriptions>
      <el-table
        stripe
        :data="bill?.payments"
        :cell-style="() => 'text-align:center;'"
        style="width: 100%; z-index: 0"
      >
        <el-table-column label="código" prop="ref"></el-table-column>
        <el-table-column label="ordem" prop="order"></el-table-column>
        <el-table-column label="total" prop="total"></el-table-column>
        <el-table-column
          label="valor"
          :formatter="(r) => currencyFormatter.format(Number(r.amount) || 0)"
        ></el-table-column>
        <el-table-column
          label="valor pago"
          :formatter="
            (r) =>
              r.amount_paid
                ? currencyFormatter.format(Number(r.amount_paid) || 0)
                : '-'
          "
        ></el-table-column>
        <el-table-column
          label="vencimento"
          prop="expires_at"
          :formatter="(r) => dateFormatter.format(new Date(r.expires_at))"
        ></el-table-column>
        <el-table-column
          label="pago em"
          :formatter="
            (r) => (r.paid_at ? dateFormatter.format(new Date(r.paid_at)) : '-')
          "
        ></el-table-column>
        <el-table-column label="ações">
          <template #default="bill">
            <el-row type="flex" justify="end">
              <el-button-group>
                <el-button
                  onlyIcon
                  type="primary"
                  title="Imprimir na bematech"
                  circle
                  @click="printBill(bill.row)"
                >
                  <i class="el-icon-printer"></i
                ></el-button>
                <el-button
                  onlyIcon
                  type="primary"
                  title="Baixar esta parcela da conta"
                  circle
                  @click="openPayBillModal(bill.row)"
                >
                  <i class="el-icon-takeaway-box"></i
                ></el-button>
                <el-popconfirm
                  @confirm="
                    updatePayment({
                      ...bill.row,
                      paid_at: null,
                      amount_paid: null,
                    })
                  "
                  title="
              Cancelar baixa?
            "
                  ><template #reference>
                    <el-button
                      onlyIcon
                      title="Cancelar baixa da parcela"
                      type="warning"
                      circle
                    >
                      <i class="el-icon-circle-close"></i></el-button></template
                ></el-popconfirm>
                <el-popconfirm
                  @confirm="deletePayment(bill.row)"
                  title="
              Remover parcela?
            "
                  ><template #reference>
                    <el-button
                      onlyIcon
                      title="Apagar parcela da conta"
                      type="danger"
                      circle
                    >
                      <i
                        class="el-icon-delete btn-effect-danger"
                      ></i></el-button></template
                ></el-popconfirm>
              </el-button-group>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <el-row justify="end" type="flex">
        <el-button type="text" @click="shouldShow = false" class="button"
          >Fechar</el-button
        >
      </el-row>
    </template>
    <pay-bill-modal
      :showModal="showPayBillModal"
      @should-update="$emit('should-update')"
      :refId="ref"
      @close-modal="showPayBillModal = false"
    ></pay-bill-modal>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import PayBillModal from "./PayBillModal.vue";
import { ElNotification } from "element-plus";
import { notifyError, notifySuccess } from "../../utils/notifiers";
import SystemService from "../../services/system";
export default {
  props: ["showModal", "bill"],
  emits: ["close-modal", "should-update"],
  components: { PayBillModal },
  watch: {
    bill(v) {
      if (v) {
        const unpaid = this.getFirstUnpaidPayment();
        if (unpaid) {
          this.amount_paid = unpaid.amount;
          this.paid_at = new Date(unpaid.expires_at);
        }
      } else {
        this.shouldShow = false;
      }
    },
  },
  data() {
    return {
      billCode: "",
      paid_at: null,
      delayFetchBill: null,
      amount_paid: 0,
      loading: null,
      obs: "",
      ref: "",
      isLoadingSave: false,
      showPayBillModal: false,
      isLoading: false,
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
      }),
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }),
      numberFormatter: new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
      }),
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    deletePayment(p) {
      if (p) {
        fetch(`${this.$store.state.apiUrl}payments/${p.uid}`, {
          credentials: "include",
          method: "DELETE",
        })
          .then((r) => {
            if (r.status === 200) return r.json();
            else throw r.json();
          })
          .then(
            () => {
              ElNotification.success({
                title: "Parcela removida com sucesso",
              });
              this.$emit("should-update");
            },
            () =>
              ElNotification.error({
                title: "Não foi possível remover a parcela",
              })
          );
      }
    },
    updatePayment(p) {
      if (p) {
        fetch(`${this.$store.state.apiUrl}payments/${p.uid}`, {
          credentials: "include",
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(p),
        })
          .then((r) => {
            if (r.status === 200) return r.json();
            else throw r.json();
          })
          .then(
            () => {
              ElNotification.success({
                title: "Baixa cancelada com sucesso",
              });
              this.$emit("should-update");
            },
            () =>
              ElNotification.error({
                title: "Não foi possível remover a parcela",
              })
          );
      }
    },
    async printBill(payment) {
      if (this.$store.state.user.printer?.uid) {
        const { job } = await SystemService()
          .Printers(this.$store.state.user.printer.uid)
          .Job()
          .create({
            layout: "bills",
            print_content: JSON.stringify({
              ...payment,
              bill: this.bill,
            }),
          });

        if (job) notifySuccess("Impressão enviada para a impressora");
        else
          notifyError("Não foi possível enviar a impressão para a impressora");
      } else {
        await SystemService()
          .Print()
          .Bill(
            new URLSearchParams({
              codConta: payment?.ref,
              empresa: this.bill?.firm?.name,
              fornecedor: this.bill?.partner?.name,
              centroCusto: `${
                this.bill?.cost?.category ? this.bill?.cost?.category : ""
              }${
                this.bill?.cost?.subcategory
                  ? " -> " + this.bill?.cost?.subcategory
                  : ""
              }${this.bill?.cost?.name ? " -> " + this.bill?.cost?.name : ""}${
                this.bill?.cost?.entity ? " -> " + this.bill?.cost?.entity : ""
              }`,
              dataAquisicao: new Date(
                this.bill?.bought_at
              ).toLocaleDateString(),
              dataVencimento: new Date(
                payment?.expires_at
              ).toLocaleDateString(),
              dataVencimentoSobra: "-",
              valor: this.numberFormatter.format(new Number(payment?.amount)),
              valorPendente: this.numberFormatter.format(
                this.getRemainingAmountFromPayment(payment)
              ),
              descricao: this.bill?.obs || "",
              numeroParcela: payment?.order,
              qtdParcela: this.bill?.quota || "1",
            })
          );
      }
    },
    getRemainingAmount() {
      const total = Number(this.bill?.amount);
      if (total) {
        const paid = this.bill?.payments
          ?.filter((p) => p.status === "paid")
          ?.map((p) => Number(p.amount_paid))
          ?.reduce((t, p) => (t += p), 0);

        return total - paid;
      }
      return total || 0;
    },
    openPayBillModal(e) {
      (this.ref = e?.ref), (this.showPayBillModal = true);
    },
    getRemainingAmountFromPayment(payment) {
      let remaining = this?.bill?.amount;
      if (payment) {
        const order = payment.order;
        for (let p of this.bill?.payments) {
          if (p.order === order) break;

          remaining -= Number(p.amount);
        }
      }

      return remaining;
    },
    showLoading() {
      this.loading = this.$loading({
        target: ".PayBill",
        text: "Carregando conta",
      });
    },
    closeLoading() {
      this.loading?.close();
    },
    getFirstUnpaidPayment() {
      if (!this.bill) return null;
      let unpaid = this.bill?.payments.find((p) => p.status != "paid");
      if (!unpaid && !this.bill?.payments?.length)
        unpaid = {
          order: 1,
          total: 1,
          expires_at: this.bill.expires_at,
          amount: this.bill.amount,
        };

      return unpaid;
    },
  },
  name: "BillPaymentsModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-2 {
  margin-top: 8px;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>
