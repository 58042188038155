<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>Contas Cadastradas</h4>
          </el-row>
        </el-col>
        <el-col :md="16" :xs="24">
          <el-row type="flex" justify="end">
            <el-dropdown
              @command="(c) => filterBills(c)"
              class="mr-8"
              size="medium"
              trigger="click"
            >
              <el-button type="primary" size="medium">
                Exibir contas por: {{ displayPage || ""
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="Em aberto">
                    <li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Em aberto',
                      }"
                    >
                      Em aberto
                    </li>
                  </el-dropdown-item>
                  <el-dropdown-item command="Indiferente">
                    <li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Indiferente',
                      }"
                    >
                      Indiferente
                    </li>
                  </el-dropdown-item>
                  <el-dropdown-item command="Vencimento no dia"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Vencimento no dia',
                      }"
                    >
                      Vencimento no dia
                    </li></el-dropdown-item
                  >
                  <el-dropdown-item command="Vencidas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Vencidas',
                      }"
                    >
                      Vencidas
                    </li></el-dropdown-item
                  >
                  <el-dropdown-item command="Baixadas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Baixadas',
                      }"
                    >
                      Baixadas
                    </li></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openBillsModal(null)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-s-operation"
              size="medium"
              @click="openFilterBillsModal()"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-takeaway-box"
              size="medium"
              @click="openPayBillModal()"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :data="bills?.data"
      @sort-change="sortTable"
      style="width: 100%; z-index: 0"
      :cell-style="() => 'text-align:center;'"
    >
      <el-table-column prop="bill.firm.name" label="empresa"> </el-table-column>
      <el-table-column
        prop="bill.partner.name"
        label="fornecedor"
        min-width="250"
      >
      </el-table-column>
      <el-table-column
        prop="bill.cost.category"
        label="centro de custo"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        label="valor"
        :formatter="(r) => formatCurrency(r.amount)"
      >
      </el-table-column>
      <el-table-column
        label="valor pago"
        :formatter="(r) => formatCurrency(r.amount_paid)"
      >
      </el-table-column>
      <el-table-column
        label="parcelas"
        :formatter="(r) => formatOrders(r)"
        min-width="50"
      >
      </el-table-column>
      <el-table-column
        label="vencimento"
        sortable="custom"
        prop="expires_at"
        :formatter="(r) => formatDate(new Date(r?.expires_at))"
      ></el-table-column>
      <el-table-column label="ações" :min-width="130">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                title="Detalhes da conta e parcelas"
                circle
                @click="openBillPaymentsModal(cat.row?.bill)"
              >
                <i class="el-icon-tickets"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                title="Editar conta"
                circle
                @click="openBillsModal(cat.row?.bill)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteBill(cat.row?.bill)"
                title="
              Remover conta?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" title="Apagar conta" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="bills?.lastPage"
    >
    </el-pagination>
    <bill-modal
      :showModal="showBillModal"
      :bill="bill"
      @close-modal="showBillModal = false"
      @should-update="fetchBills"
    ></bill-modal>

    <filter-bills-modal
      :showModal="showFilterBillsModal"
      @close-modal="showFilterBillsModal = false"
      @update-filters="updateFilters"
    ></filter-bills-modal>

    <pay-bill-modal
      :showModal="showPayBillModal"
      @should-update="fetchBills"
      :refId="ref"
      @close-modal="showPayBillModal = false"
    ></pay-bill-modal>

    <bill-payments-modal
      :showModal="showBillPaymentsModal"
      @should-update="fetchBills"
      :bill="bill"
      @close-modal="showBillPaymentsModal = false"
    ></bill-payments-modal>
  </el-card>
</template>

<script>
import BillModal from "./modals/BillModal.vue";
import FilterBillsModal from "./modals/FilterBillsModal.vue";
import PayBillModal from "./modals/PayBillModal.vue";
import BillPaymentsModal from "./modals/BillPaymentsModal.vue";
//import { ElNotification, ElMessageBox } from "element-plus";
export default {
  name: "BillsPage",
  components: { BillModal, FilterBillsModal, PayBillModal, BillPaymentsModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    bills: null,
    bill: null,
    ref: null,
    displayPage: "",
    filterOptions: {},
    displayOptions: {},
    currentPage: 1,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    showBillModal: false,
    showBillPaymentModal: false,
    showPayBillModal: false,
    showFilterBillsModal: false,
    showBillPaymentsModal: false,
  }),
  mounted() {
    this.filterBills("Em aberto");
  },
  computed: {
    SearchQuery() {
      return this.$store.state.searchNameQuery;
    },
  },
  watch: {
    currentPage() {
      this.fetchBills();
    },
    bills() {
      if (this.bill) {
        this.bill = this.bills?.data?.find((b) => b.uid === this.bill.uid);
      }
    },
    SearchQuery() {
      this.fetchBills();
    },
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
    deleteBill(bill) {
      fetch(`${this.$store.state.apiUrl}bills/${bill.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchBills();
          this.$notify({
            title: "Conta removida com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover a conta",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    openBillsModal(e) {
      (this.bill = e), (this.showBillModal = true);
    },
    openPayBillModal(e) {
      (this.ref = e?.ref), (this.showPayBillModal = true);
    },
    sortTable(b) {
      console.log(b);
      if (b?.column) {
        if (!this.filterOptions.ordenation) this.filterOptions.ordenation = {};
        this.filterOptions.ordenation[`${b.prop}`] = `${b.order}`;
        this.fetchBills();
      } else {
        this.filterOptions.ordenation = {};
        this.fetchBills();
      }
    },
    openFilterBillsModal() {
      this.showFilterBillsModal = true;
    },
    openBillPaymentsModal(bill) {
      this.bill = bill;
      this.showBillPaymentsModal = true;
    },
    allPaid(payments) {
      return payments?.every((p) => p.status === "paid");
    },
    updateFilters(filters) {
      if (filters) this.filterOptions = filters;
      this.fetchBills();
    },
    formatOrders(b) {
      if (b?.payments) {
        const order = b.payments.reduce(
          (t, p) => (t += p.status === "paid" ? 1 : 0),
          0
        );
        return `${order || 0}/${b?.payments?.length || 1}`;
      }
      return `${b.order || "1"}/${b.total || "1"}`;
    },
    getAmountPaid(bill) {
      if (bill?.payments) {
        return this.formatCurrency(
          bill.payments.reduce(
            (t, p) => (t += p.status === "paid" ? p.amount_paid : 0),
            0
          )
        );
      }
      return this.formatCurrency(0);
    },
    filterBills(filter) {
      let shouldCallFetch = this.displayPage != filter;

      this.displayPage = filter;
      if (!this.filterOptions) this.filterOptions = {};
      this.displayOptions = {};
      let _;
      switch (filter) {
        case "Em aberto":
          this.displayOptions.status = "unpaid";
          break;
        case "Vencimento no dia":
          _ = new Date();
          this.displayOptions.status = "unpaid";
          this.displayOptions.expires_at_start = new Date(
            _.setUTCHours(0, 0, 0, 0)
          );
          this.displayOptions.expires_at_end = new Date(
            _.setUTCHours(23, 59, 59, 999)
          );
          break;
        case "Vencidas":
          _ = new Date();
          _.setUTCHours(0, 0, 0, 0);
          this.displayOptions.status = "unpaid";
          this.displayOptions.expires_at_end = _;
          break;

        case "Baixadas":
          this.displayOptions.status = "paid";
          break;
      }
      if (shouldCallFetch) this.fetchBills();
    },
    fetchBills() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}payments`);

      this.filterBills(this.displayPage);

      url.search = new URLSearchParams({
        ...(this.filterOptions || {}),
        ...(this.displayOptions || {}),
        ordenation: JSON.stringify(this.filterOptions.ordenation || {}),
        searchName: this.SearchQuery,
        pagination: this.currentPage,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.bills = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style>
.el-card {
  width: 100%;
}
li.active {
  background-color: #409eff;
  color: white;
  padding-left: 17px;
}
li.no-padding {
  margin-left: -17px !important;
  margin-right: -17px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.cell {
  word-break: break-word !important;
}

.sort-caret.ascending {
  top: -5px !important;
}

.sort-caret.descending {
  bottom: -3px !important;
}
</style>